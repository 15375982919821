
.figure-container {
    fill: transparent;
    stroke: #000000cc;
    stroke-width: 4px;
    stroke-linecap: round;
  }

  .android-figure-container {
    fill: transparent;
    stroke: #000000cc;
    stroke-width: 4px;
    stroke-linecap: round;
  }

  .ios-figure-container {
    fill: transparent;
    stroke: #000000cc;
    stroke-width: 4px;
    stroke-linecap: round;
    margin-left:30px;
  }
  
  .figure-part {
    display: none;
  }