
.word {
    display: flex;
    position: fixed;
    bottom: 30%;
    left: 50%;
    transform: translateX(-50%);
  }

  .android-word {
    display: flex;
    position: fixed;
    bottom: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  .ios-word {
    display: flex;
    position: fixed;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
  }

.letter {
    border-bottom: 4px solid #b40000;
    display: inline-flex;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    height: 50px;
    width: 20px;
  }
  /* @media only screen and (max-width: 600px) {
    .wrong-letters-container {
      padding: 20px;
      position: absolute;
      top: 20px;
      right: 25px;
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  } */