.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}
.header-text {
  text-align: center;
}

.header-message {
  font-weight: bold;
  font-size: 18px;
}

.logo-image {
  width: 50%;
  height: auto;
}
@media only screen and (max-width: 600px) {

  .header-text {
    text-align: center;
    margin-top: -4%;
  }

  .header-message {
    font-weight: bold;
    font-size: 18px;
  }

  /*ANDROID STYLES*/
  .android-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
  }
  .android-logo-image {
    width: 75%;
    height: auto;
    padding-top: 10px;
  }

  /*IOS STYLES*/
  .ios-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
  }

  .ios-logo-image {
    width: 75%;
    height: auto;
    padding-top: 15px;
  }
  
}
