/* CustomKeyboard.css */
.keyboard-container { 
  display: flex; 
  /* height: 100vh;  */
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  width: 90%;  
  /* margin: 15px;  */
  max-width: 980px;
  
}

.custom-keyboard {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 2px;
  position: fixed;
  bottom: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-keyboard2 {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 2px;
  position: fixed;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-keyboard3 {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 2px;
  position: fixed;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-ios-keyboard {
  display: flex;
  grid-template-columns: repeat(10, 1fr);
  gap: 1px;
  position: fixed;
  bottom: 14%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.custom-ios-keyboard2 {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 1px;
  position: fixed;
  bottom: 7%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-ios-keyboard3 {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 1px;
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/* Style for each row */
/* .custom-keyboard .row,
.custom-keyboard2 .row,
.custom-keyboard3 .row {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
} */
/* Adjust specific buttons for indentation */
/* .custom-keyboard .row:nth-child(2) button {
  margin-left: 0px;
}

.custom-keyboard .row:nth-child(3) button {
  margin-left: 40px;
}

.custom-keyboard .row:nth-child(4) button {
  margin-left: 60px;
}

.custom-keyboard .row:nth-child(5) button {
  margin-left: 80px;
} */


/* Style for all buttons */
.custom-keyboard button,
.custom-keyboard2 button,
.custom-keyboard3 button {
  display: flex; 
  width:5px; 
  height: 40px; 
  margin: 0px; 
  justify-content: center; 
  flex-direction: column; 
  align-items: center; 
  background-color: #052f5f;
  border-radius: 4px; 
  color: #ffffff; 
  font-size: 16px; 
  cursor: pointer; 
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
  transition: background-color ease-in-out 0.15s; 
}

.custom-ios-keyboard button,
.custom-ios-keyboard2 button,
.custom-ios-keyboard3 button {
  display: flex; 
  width:5px; 
  height: 35px; 
  margin: 0px; 
  justify-content: center; 
  flex-direction: column; 
  align-items: center; 
  background-color: #052f5f;
  border-radius: 4px; 
  color: #ffffff; 
  font-size: 16px; 
  cursor: pointer; 
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
  transition: background-color ease-in-out 0.15s; 
}


/* Hover effect */
.custom-keyboard button:hover,
.custom-keyboard2 button:hover,
.custom-keyboard3 button:hover {
  background-color: #e0e0e0;
}

/* Active effect */
.custom-keyboard button:active,
.custom-keyboard2 button:active,
.custom-keyboard3 button:active  {
  background-color: #d3d3d3;
}
