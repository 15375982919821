
.notification-container {
    background-color: rgba(255, 203, 61, 0.384);
    border-radius: 10px 10px 10px 10px;
    padding: 15px 20px;
    position: absolute;
    bottom: -20%;
    transition: transform 0.5s ease-in-out;
  }
  
  .notification-container p {
    margin: 0;
  }
  
  .notification-container.show {
    transform: translateY(-350px);
  }
  