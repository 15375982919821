
.popup-container {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* display: flex; */
    display: none;
    align-items: center;
    justify-content: center;
  }
  
  .popup {
    background: #ecf0f1;
    border-radius: 10px;
    box-shadow: 0 15px 10px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  
  }
  
  .popup-button {
    cursor: pointer;
    background-color: #0cac17de;
    color: #ffffff;
    border: 1;
    border-color: #0cac17de;
    margin-top: 20px;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .popup button:active {
    transform: scale(0.98);
  }
  
  .popup button:focus {
    outline: 0;
  }
  
  .popup form {
    margin-top: 20px;
  }
  .popup input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .popup button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .popup button[type="submit"]:hover {
    background-color: #45a049;
  }