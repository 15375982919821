.centered-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Align items vertically and horizontally */
}

.checkbox-container {
  display: flex;
  flex-direction: column; /* Display items in a vertical column */
  align-items: center; /* Align items horizontally */
  /* margin-top: 20px; Add margin between centered text and checkbox content */
  padding-bottom: 40px;
}

.checkbox-content {
  display: flex;
  flex-direction: row; /* Display items in a horizontal row */
  align-items: center; /* Align items vertically */
  /* margin-bottom: 10px; Add spacing between items */
  padding-bottom: -15px;
  margin-left: 20%; /* Add margin to the left side */
}

.check-image {
  max-width: 5%;
  margin-right: 10px; /* Add spacing between image and text */
}

.text1 {
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.text2 {
  font-size: 20px;
  color: black;
  font-weight: bold;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%; 
}

@media screen and (max-width: 600px) {
.splash-image{
  width: 40vh;
  height:auto;
}
}


@media screen and (min-width: 601px) and (max-width: 1200px) {
  .splash-image{
    width: 80vh;
    height:auto;
  }
  }