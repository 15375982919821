.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: #ecf0f1;
    border-radius: 10px;
    box-shadow: 0 15px 10px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  textarea {
    width: calc(100% - 40px);
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .submit-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 0;
    text-align: center;
    text-decoration: none;
    display: block;
    width: calc(100% - 40px);
    font-size: 16px;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .submit-button:active {
    background-color: #3e8e41;
  }
  
    
  .cancel-button {
    background-color: #af4c4c;
    border: none;
    color: white;
    padding: 15px 0;
    text-align: center;
    text-decoration: none;
    display: block;
    width: calc(100% - 40px);
    font-size: 16px;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 4px;
    margin-top:10px
  }

  .cancel-button:hover {
    background-color: #a04545;
  }
  
  .cancel-button:active {
    background-color: #8e3e3e;
  }
  