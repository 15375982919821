/*=====WEB=======*/

.wrong-letters-container {
    padding: 20px;
    position: absolute;
    top: 20px;
    right: 5px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .wrong-letters-container p {
    /* margin: 0 0 5px; */
    font-weight: bold;
  }
  
  .wrong-letters-container span {
    font-size: 24px;
  }

/*======Android=============================================================*/
  .android-wrong-letters-container {
    padding: 20px;
    position: absolute;
    top: 5%;
    right: 15%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .android-wrong-letters-container p {
    /* margin: 0 0 5px; */
    font-weight: bold;
  }
  
  .android-wrong-letters-container span {
    font-size: 24px;
  }


/*======IOS=============================================================*/
  .ios-wrong-letters-container {
    padding: 20px;
    position: absolute;
    top: 5%;
    right: 15%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .ios-wrong-letters-container p {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .ios-wrong-letters-container span {
    font-size: 24px;
  }


 
