/* CategoryModal.css */

.modal {
    position: fixed;
    top: 25%;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* background-color: #ffffff8a; */
    padding: 20px;
    border-radius: 5px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  
  h2 {
    margin-bottom: 20px;
   text-align: center;
  }
  
  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #052f5f;;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #0057b3ab;
    color:white;
  }
  
  .modal select {
    font-size: 16px; /* Adjust the font size as needed */
  }
  
  .modal option {
    font-size: 16px; /* Adjust the font size as needed */
  }