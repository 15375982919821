/* HighscorePage.css */
.highscore-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ecf0f1;
  border-radius: 10px;
  box-shadow: 0 15px 10px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  overflow-y: scroll; /* Enable vertical scrolling */
}

.highscore-page {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999; 
  }
  .highscore-logo-image{
    width: 50%;
    height: auto;
  }

  .highscore-list{
    max-height: 300px; 
  }
  .highscore-list ul {
    list-style: none; 
    padding: 0;

  }
  
  .highscore-list li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .highscore-list li p {
    font-size: 28px;
    font-weight: bold;
    margin: 0; 
    text-align: right; 
    width: 5%; 
    margin-right: 10%;
    margin-left: 5%;
  }

  .highscore-logo-image{
    width: 35%;
    height: auto;
  }

  @media only screen and (max-width: 600px) {
    .highscore-logo-image{
      width: 100%;
      height: auto;
    }
    .highscore-list li p {
      font-size: 22px;
      font-weight: bold;
      margin: 0; 
      text-align: right; 
      width: 5%; 
      margin-right: 25%;
      margin-left: 15%;
    }
  
  }