* {
  box-sizing: border-box;
}

body,
#root {
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  margin: 0;
  overflow: hidden;
  background-color: transparent;
}

body {
  background-image: var(--background-image);
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}

h1 {
  margin: 20px 0 0;
}

.game-container {
  padding: 20px 30px;
  position: relative;
  margin: auto;
  height: 350px;
  width: 450px;
}

.scoreboard {
  position: fixed;
  left: 75%;
  bottom: 90%;
  font-size: 24px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.score-button {
  color: #052f5f;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  margin-left: 25%;
  background-color: transparent;
  border: 0;
  cursor: none;
}

.high-score-button {
  color: #052f5f;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.feedback-button {
  color: #052f5f;
  font-size: 20px;
  font-weight: bold;
  margin-left: 5%;
  margin-right: 25%;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.close-button {
  /* position:fixed; */
  margin-top: 15%;
  font-weight: bold;
  background-color: rgb(220, 101, 101);
  cursor: pointer;
}

/* ===================MOBILE STYLES======================*/

@media only screen and (max-width: 600px) {
  .android-game-container {
    top: 5%;
    padding: 20px 30px;
    position: relative;
    margin: 0;
    height: 350px;
    width: 450px;
  }
  .android-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .android-score-button,
  .android-high-score-button,
  .android-feedback-button {
    padding: 10px 20px;
    background-color: transparent;
    font-weight: bold;
    color: #052f5f;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    top: 0;
    margin: 15px;
    margin-top: 5px;
    text-wrap: nowrap;
    -webkit-text-wrap: none;
    white-space: nowrap;
  }
  .ios-game-container {
    padding: 20px 30px;
    position: relative;
    margin: 0;
    height: 350px;
    width: 450px;
  }
  .ios-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999;
  }

  .ios-score-button,
  .ios-high-score-button,
  .ios-feedback-button {
    padding: 10px 20px;
    background-color: transparent;
    font-weight: bold;
    color: #052f5f;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    top: 0;
    /* margin:15px; */
    margin-top: 5px;
    text-wrap: nowrap;
    -webkit-text-wrap: none;
    white-space: nowrap;
  }

  /* .scoreboard {
    position: fixed;
    left: 20%;
    transform: translateX(-50%);
    top: 27%;
    font-size: 24px;
  } */

  /* .game-container {
    padding: 20px 30px;
    position: relative;
    margin: 0;
    height: 350px;
    width: 450px;
  } */
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
